import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { ShopRegisterRoutingModule } from './shop-register-routing.module';
import { HomeMapComponent } from './pages/home-map/home-map.component';
import { PermissionRequestModalComponent } from './components/permission-request-modal/permission-request-modal.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatRippleModule} from "@angular/material/core";
import { AddShopComponent } from './pages/add-shop/add-shop.component';
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import { CameraImageFieldComponent } from './components/camera-image-field/camera-image-field.component';
import { RestrictAppModalComponent } from './components/restrict-app-modal/restrict-app-modal.component';
import { OtpModalComponent } from './components/otp-modal/otp-modal.component';


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    HomeMapComponent,
    PermissionRequestModalComponent,
    AddShopComponent,
    CameraImageFieldComponent,
    RestrictAppModalComponent,
    OtpModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    MatDialogModule,
    ShopRegisterRoutingModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule
  ]
})
export class ShopRegisterModule { }
