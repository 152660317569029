export const ShopSizes = [
  'Sq Ft',
  'Sq Yd',
  'Gaj'
]

export const ShopTypes = [
  {
    type: 'Lube Shop'
  },
  {
    type: 'Auto Parts',
    subTypes: ['2-wheeler', '3-wheeler', '4-wheeler-Car/LCV/SUV', 'TRACTOR', 'HCV-Diesel']
  },
  { type: 'Tyre Shop' },
  { type: 'Battery Shop' },
  {
    type: 'Mechanic',
    subTypes: ['2-wheeler', '3-wheeler', '4-wheeler-Car/LCV/SUV', 'TRACTOR', 'Diesel Mechanic']
  },
  { type: 'Garage', subTypes: ['Car', 'Trucks'] }
]

export const ShopSizeType = ['upto 150 Sq.Ft.', '150-300 Sq.Ft.', '> 300 Sq.Ft.']

export const AvailableBrands = [
  'HPCL',
  'Servo',
  'Mak',
  'Castrol',
  'Gulf',
  'Total',
  'Valvoline',
  'Shell',
  'Veedol',
  'Savsol',
  'Others'
]

