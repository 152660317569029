import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-restrict-app-modal',
  templateUrl: './restrict-app-modal.component.html',
  styleUrls: ['./restrict-app-modal.component.scss']
})
export class RestrictAppModalComponent implements OnInit {
  heading: string;
  description: string;

  constructor(
    public dialogRef: MatDialogRef<RestrictAppModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) { }

  ngOnInit(): void {
    this.initComponent();
  }

  ionViewWillEnter() {
    this.initComponent();
  }

  initComponent() {
    this.heading = this.dialogData?.header ?? 'Application is disabled';
    this.description = this.dialogData?.description ?? 'You location and/or device do not meet conditions required to run the application.';
  }

}
