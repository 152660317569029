import { Injectable } from '@angular/core';
import {AuthService} from "./auth.service";

@Injectable()
export class AppInitService {

  constructor(
    private authService: AuthService
  ) { }

  init() {
    const hasInitializedPromise = new Promise<void>(async (resolve, reject) => {
      const user = await this.authService.setAuthStatus();
      console.log(user);
      resolve();
    });
    return hasInitializedPromise;
  }
}
