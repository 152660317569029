import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GoogleMap, Marker} from '@capacitor/google-maps';
import {Geolocation, Position} from '@capacitor/geolocation';
import {environment} from '../../../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {
  PermissionRequestModalComponent
} from '../../components/permission-request-modal/permission-request-modal.component';
import {LocationService} from '../../services/location-service.service';
import {PageRoutes} from '../../../../constants/page-routes.constant';

@Component({
  selector: 'app-home-map',
  templateUrl: './home-map.component.html',
  styleUrls: ['./home-map.component.scss']
})
export class HomeMapComponent implements OnInit {

  @ViewChild('shopMap') mapRef: ElementRef<HTMLElement>;

  googleMap: GoogleMap;
  public folder: string;
  currenLocation!: Position;
  currentMarker!: string;
  locationUpdateTimer: any;
  isInitialLocationSet = false;

  apiFlags = {
    mapLoading: false,
    locationLoading: false
  };

  constructor(
    private locationService: LocationService,
    private router: Router,
    private dialogService: MatDialog,
  ) { }

  ngOnInit() {
    this.folder = 'Inbox';
  }

  ionViewDidEnter() {
    this.apiFlags = {
      mapLoading: false,
      locationLoading: false
    };
    this.apiFlags.mapLoading = true;
    this.currenLocation = null;
    this.currentMarker = null;

    this.isInitialLocationSet = false;
    this.locationService.checkAndGetLocationPermission().then(() => {
      this.initMap();
    });
  }

  ionViewWillLeave() {
    clearInterval(this.locationUpdateTimer);
    this.googleMap.destroy();
  }

  async initMap() {
    this.googleMap = await GoogleMap.create({
      id: 'shop-maps',
      element: this.mapRef.nativeElement,
      apiKey: environment.googleMapApiKey,
      config: {
        center: {
          lat: 33.6,
          lng: -117.9,
        },
        zoom: 8,
      },
    });
    this.apiFlags.mapLoading = false;
    this.locationUpdateTimer = setInterval(async () => {
      await this.initCurrentLocation();
    }, 1500);
  }

  async initCurrentLocation() {
    this.currenLocation = null;
    this.apiFlags.locationLoading = true;
    // await this.googleMap.enableCurrentLocation(true);
    const myLocation = await this.getCurrentLocation();
    await this.googleMap.setCamera({
      coordinate: {
        lat: myLocation.coords.latitude,
        lng: myLocation.coords.longitude
      },
      zoom: 15,
      animate: true,
      animationDuration: 500
    });
    this.currenLocation = myLocation;
    if (!this.isInitialLocationSet) {
      this.isInitialLocationSet = true;
    }
    this.removeMarker(this.currentMarker);
    this.currentMarker = await this.googleMap.addMarker(await this.getLocationMarker(myLocation));
    // console.log(myLocation);
    this.apiFlags.locationLoading = false;
  }

  async getCurrentLocation() {

    return await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
      maximumAge: 100
    });
  }

  async getLocationMarker(coord: Position) {
    const marker: Marker = {
      coordinate: {
        lat: coord.coords.latitude,
        lng: coord.coords.longitude
      },
      draggable: false,
      title: 'You'
    };
    return marker;
  }

  removeMarker(markerId) {
    if(!markerId) {
      return;
    }
    this.googleMap.removeMarker(markerId);
  }

  addNewShop() {
    this.router.navigate([PageRoutes.shopRegister.addShop]);
  }

}
