import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformUtilService {

  constructor(
    private platformService: Platform
  ) { }

  isWeb() {
    return (
      !this.platformService.is('android') &&
      !this.platformService.is('ios')
    ) ||
      this.platformService.is('mobileweb');
  }
}
