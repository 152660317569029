import {Injectable} from '@angular/core';
import {Geolocation, PermissionStatus} from '@capacitor/geolocation';
import {Platform} from '@ionic/angular';
import {
  PermissionRequestModalComponent
} from '../components/permission-request-modal/permission-request-modal.component';
import {MatDialog} from '@angular/material/dialog';

declare const navigator: any;

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  public isWeb!: boolean;
  public locPermission: PermissionStatus;

  constructor(
    private dialogService: MatDialog,
    private platformService: Platform
  ) {
    this.isWeb = (!this.platformService.is('android') && !this.platformService.is('ios')) || this.platformService.is('mobileweb');
  }

  async checkAndGetLocationPermission() {
    const locPermission = await Geolocation.checkPermissions();
    console.log(locPermission);
    if (locPermission.location !== 'granted') {
      const dialogRef = await this.openPermissionModal();
      const dialogResult = await dialogRef.afterClosed().toPromise();
    }
    return;
  }

  async getCurrentLocation() {
    return await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
      maximumAge: 100
    });
  }

  async requestLocationAccess() {
    if (this.isWeb) {
      const webResult = await Geolocation.checkPermissions();
      if (webResult.location !== 'denied'){
        return await this.webLocationRequest();
      }
      console.log(webResult);
      return webResult;
    }
    // @ts-ignore
    return await Geolocation.requestPermissions('location');
  }

  webLocationRequest() {
    return new Promise((resolve => {
      navigator.geolocation.getCurrentPosition(
        (status) => {resolve({location:'granted'});},
        ()=>{resolve({location:'denied'});});
    }));
  }

  async openPermissionModal() {
    return this.dialogService.open(PermissionRequestModalComponent, {
      data: {
        hasPermissionDenied: false
      },
      width: '400px',
      minHeight: '100px',
      maxHeight: '600px',
      disableClose: true,
      hasBackdrop: true,
      backdropClass: 'dark-backdrop'
    });
  }

  getLocationDetail(lat, lng) {

  }
}
