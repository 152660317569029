import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LocationService} from "../../services/location-service.service";

@Component({
  selector: 'app-permission-request-modal',
  templateUrl: './permission-request-modal.component.html',
  styleUrls: ['./permission-request-modal.component.scss']
})
export class PermissionRequestModalComponent implements OnInit {

  @Input() hasPermissionDenied = false;

  constructor(
    public dialogRef: MatDialogRef<PermissionRequestModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private locationService: LocationService
  ) { }

  ngOnInit(): void {
    this.hasPermissionDenied = this.dialogData.hasPermissionDenied ?? false;
  }

  requestLocationAccess() {
    this.locationService.requestLocationAccess().then((result: any) => {
      if (result.location === 'denied') {
        this.hasPermissionDenied = true;
      } else if (result.location === 'granted') {
        this.dialogRef.close();
      }
    });
  }
}
