import {Component, OnInit, ViewChild} from '@angular/core';
import {Position} from '@capacitor/geolocation';
import {LocationService} from '../../services/location-service.service';
import {
  AbstractControl, FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm, ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {GenericTypeModel} from '../../../../constants/models/generic-type.model';
import {ShopUtilService} from '../../services/shop-util.service';
import {ErrorStateMatcher} from '@angular/material/core';
import {ShopSizes, ShopSizeType, ShopTypes, AvailableBrands} from '../../../../constants/shop-util.constant';
import {ContactType} from '../../../../constants/models/contact-type.constant';
import {CameraImageFieldComponent} from '../../components/camera-image-field/camera-image-field.component';
import {CameraImageFieldConfigModel} from '../../../../constants/models/camera-image-field-config.model';
import {environment} from '../../../../../environments/environment';
import {Subscription} from 'rxjs';
import {ApiResponseModel} from '../../../../constants/models/api-response.model';
import {CustomToastService} from '../../../../services/custom-toast.service';
import { AuthService } from 'src/app/services/auth.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-add-shop',
  templateUrl: './add-shop.component.html',
  styleUrls: ['./add-shop.component.scss']
})
export class AddShopComponent implements OnInit {
  @ViewChild('cameraImageField') cameraImageField: CameraImageFieldComponent;

  currentLocation: Position;
  shopForm: FormGroup;
  cameraImageFieldConfig: CameraImageFieldConfigModel;
  shopTypes = ShopTypes;
  shopSizeTypes = ShopSizeType;
  availableBrands = AvailableBrands;
  hasShopSubTypes = false;
  isotherBrand = false;
  talukList = [];
  pinSub$: Subscription;

  matcher = new MyErrorStateMatcher();
  apiFlags = {
    loadingLocation: false,
    loadingShopTypes: false,
    loadingPinCodeDetails: false,
    submittingForm: false
  };

  constructor(
    private locationService: LocationService,
    private shopService: ShopUtilService,
    private fb: FormBuilder,
    private toastService: CustomToastService,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.authService.getAccessToken().then(token => console.log(token))
    this.initShopForm();
  }

  ionViewWillEnter() {
    this.setCurrentLocation();
    this.initShopForm();
  }

  ionViewDidEnter() {
    this.apiFlags = {
      loadingLocation: false,
      loadingShopTypes: false,
      loadingPinCodeDetails: false,
      submittingForm: false
    };
    this.pinSub$ = this.getField('pin').valueChanges.subscribe(
      (result) => {
        this.getField('taluk').reset(null)
        this.getField('district').reset(null)
        this.talukList = [];
        if (this.getField('pin').invalid) {
          return;
        }
        this.handlePin(result)
      }
    )
  }

  ionViewWillLeave() {
    this.pinSub$.unsubscribe();
    this.resetAllForms();
  }

  setCurrentLocation() {
    this.apiFlags.loadingLocation = true;
    this.locationService.getCurrentLocation().then(loc => {
      this.currentLocation = loc;
      console.log(this.currentLocation);
      this.apiFlags.loadingLocation = false;
    });
  }

  initShopForm() {
    this.hasShopSubTypes = false;
    this.isotherBrand = false;
    this.talukList = [];
    this.cameraImageFieldConfig = {
      fieldsList: [
        {
          title: 'Business Card Image',
          maxNumber: 1,
          minNumber: 1,
          fieldName: 'b-card-img'
        },
        {
          title: 'Shop Images',
          maxNumber: 7,
          minNumber: 1,
          fieldName: 'shop-imgs'
        }
      ]
    };
    this.createFormGroups();
    this.addShopContactGroup();
  }

  createFormGroups() {
    this.shopForm = this.fb.group({
      shopName: ['', [Validators.required]],
      shopType: [null, [Validators.required]],
      shopSubType: [null],
      shopSize: [null, [Validators.required]],
      streetAddress: ['', [Validators.required]],
      district: [{value: '', disabled: true}, [Validators.required]],
      taluk: [null, [Validators.required]],
      pin: [null, [Validators.required, Validators.min(100000), Validators.max(999999)]],
      state: [{value: 'Gujrat', disabled: true}, [Validators.required]],
      phone: [null, [Validators.required, Validators.max(9999999999), Validators.min(1000000000)]],
      availableBrands: [null, [Validators.required]],
      availableBrandsOther: [null],
      contacts: this.fb.array([])
    });
  }

  get shopContactGroups() {
    return this.shopForm.get('contacts') as FormArray;
  }

  handlePin(pincode) {
    this.apiFlags.loadingPinCodeDetails = true;
    this.shopService.getPinCodeDetail(pincode).subscribe({
      next: (result) => {
        if (result.success) {
          console.log(result)
          result.data.forEach((pin) => {
            this.talukList.push(pin.taluka)
          })
          this.getField('taluk').setValue(this.talukList[0])
          this.getField('district').setValue(result.data[0]['district'])
        } else {
          this.toastService.simpleToastMessage('Invalid Pin Code !')
        }
        this.apiFlags.loadingPinCodeDetails = false;
      }, error: (error) => {
        this.toastService.simpleToastMessage('Something went wrong, please try again !')
        this.apiFlags.loadingPinCodeDetails = false
      }
    })
  }

  onShopTypeChage(e) {
    console.log(e)
    if (e?.value['subTypes']) {
      this.hasShopSubTypes = true
      this.getField('shopSubType').addValidators(Validators.required)
    } else {
      this.hasShopSubTypes = false;
      this.getField('shopSubType').clearValidators();
    }
  }

  onAvailableBrandsChange(e) {
    const ifOther = e?.value?.some((elem) => elem === 'Others')
    if (ifOther) {
      this.isotherBrand = true;
      this.getField('availableBrandsOther').addValidators(Validators.required)
    } else {
      this.isotherBrand = false;
      this.getField('availableBrandsOther').clearValidators()
    }
  }

  addShopContactGroup() {
    this.shopContactGroups.push(this.fb.group({
      name: ['', [Validators.required]],
      phone: [null, [Validators.required, Validators.max(9999999999), Validators.min(1000000000)]]
    }));
  }

  getField(name: string) {
    return this.shopForm.get(name);
  }

  submitShop() {
    this.shopForm.markAllAsTouched();
    if (this.shopForm.invalid) {
      this.toastService.simpleToastMessage('Please input the required values (marked red).', 2500)
      return;
    } else if (!this.cameraImageField.validateInput()) {
      return;
    }
    const requestBody = this.shopForm.value;

    if (requestBody.shopType.subTypes) {
      requestBody.shopType = requestBody.shopType.type
      requestBody.shopType = requestBody.shopType + '|' + requestBody.shopSubType.join('#')
    } else {
      requestBody.shopType = requestBody.shopType.type
    }
    requestBody.availableBrands = requestBody.availableBrands.join('#')
    if (requestBody.availableBrandsOther) {
      requestBody.availableBrands = requestBody.availableBrands + '|' + requestBody.availableBrandsOther
    }
    const imgObjList = this.cameraImageField.getAllUploadedImages();
    requestBody['longitude'] = this.currentLocation.coords.longitude
    requestBody['latitude'] = this.currentLocation.coords.latitude
    requestBody['accuracy'] = this.currentLocation.coords.accuracy
    requestBody['businessCardImages'] = []
    requestBody['shopImages'] = []
    imgObjList['b-card-img'].forEach((img) => {
      requestBody.businessCardImages.push(img.fileId)
    })
    imgObjList['shop-imgs'].forEach((img) => {
      requestBody.shopImages.push(img.fileId)
    })
    console.log(requestBody);
    this.addNewShop(requestBody);
  }

  addNewShop(req) {
    this.shopService.submitNewShop(req).subscribe({
      next: (resp) => {
        if (resp.success) {
          this.resetAllForms();
          this.toastService.simpleToastMessage('New shop added successfully !', 3000)
        } else {
          this.toastService.simpleToastMessage(`ERROR: ${resp.error.msg}!`)
        }
      },
      error: (error) => {
        this.toastService.simpleToastMessage('SERVER ERROR ! Please try again later.', 3000)
        this.resetAllForms();
      }
    })
  }

  resetAllForms() {
    this.setCurrentLocation();
    this.createFormGroups();
    this.hasShopSubTypes = false;
    this.isotherBrand = false;
    this.talukList = [];
    this.cameraImageField.initComponent();
  }

}
