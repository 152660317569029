import {Injectable} from '@angular/core';
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';
import {Platform} from '@ionic/angular';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class CameraUtilService {
  public isWeb!: boolean;

  constructor(
    private platformService: Platform
  ) {
    this.isWeb = (!this.platformService.is('android') && !this.platformService.is('ios')) || this.platformService.is('mobileweb');
  }

  checkPermission() {
    return Camera.checkPermissions();
  }

  requestPermission() {
    // @ts-ignore
    if (this.isWeb) {
      return new Promise((resolve => {
        resolve(true);
      }));
    }
    // @ts-ignore
    return Camera.requestPermissions('camera');
  }

  async getPhoto() {
    return await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
      saveToGallery: true
    });
  }

  blobToFile(blob) {
    const imageBlob: Blob = blob;
    const imageName: string = this.generateName();
    const imageFile: File = new File([imageBlob], imageName, {
      type: 'image/jpeg'
    });
    return window.URL.createObjectURL(imageFile);
  }

  private generateName() {
    return uuidv4() + '.jpg';
  }
}
