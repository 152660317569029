// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'hpcl-tracking',
    appId: '1:122645953008:web:67110efe930d3b2b6e8535',
    storageBucket: 'hpcl-tracking.appspot.com',
    apiKey: 'AIzaSyAE3ivN_GJI1Cr4um0fm202srzXORIDwhM',
    authDomain: 'hpcl-tracking.firebaseapp.com',
    messagingSenderId: '122645953008',
    measurementId: 'G-C86NQRXPM5',
  },
  production: false,
  googleMapApiKey: 'AIzaSyC25WpBZfqVSgimvmYLn2xi07ZWQvR-TnY'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
