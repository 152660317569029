import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams} from '@angular/common/http';
import {ApiResponseModel} from '../constants/models/api-response.model';
import {Observable} from 'rxjs';
import {PlatformUtilService} from './platform-util.service';

@Injectable({
  providedIn: 'root'
})
export class CustomApiService {

  constructor(
    private http: HttpClient,
    private myPlatformService: PlatformUtilService
  ) { }

  getRequest(url: string,
             params?: HttpParams|{[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>},
             headers?: HttpHeaders | {[header: string]: string | string[]},
  ): Observable<ApiResponseModel<any>> {
    const optionObj: HttpHeaders = this.makeHttpOptionObj(params, headers)

    // @ts-ignore
    return this.http.get<ApiResponseModel<any>>(this.getCompleteUrl(url), optionObj)
  }

  postRequest(url: string,
              body: any,
              params?: HttpParams|{[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>},
              headers?: HttpHeaders | {[header: string]: string | string[]},
  ): Observable<ApiResponseModel<any>> {
    const optionObj: HttpHeaders = this.makeHttpOptionObj(params, headers)

    // @ts-ignore
    return this.http.post<ApiResponseModel<any>>(this.getCompleteUrl(url), body, optionObj)
  }

  getCompleteUrl(path: string): string {
    if (path.includes('https://') || path.includes('http://')) {
      return path
    }
    // return `${environment.apiBase}${path}`
    if (this.myPlatformService.isWeb()) {
      return `${path}`
    }
    return `https://hpc.dev.yashkulshreshtha.me${path}`
  }

  makeHttpOptionObj(
    params?: HttpParams|{[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>},
    headers?: HttpHeaders | {[header: string]: string | string[]},
  ) {
    const optionObj: any = {}
    if (params) {
      optionObj['params'] = params
    }
    if (headers) {
      optionObj['headers'] = {
        ...headers
      }
    }
    return optionObj;
  }
}
