import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeMapComponent } from './pages/home-map/home-map.component';
import {AddShopComponent} from './pages/add-shop/add-shop.component';
import {AuthGuard} from '../../guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'map',
    pathMatch: 'full'
  },
  {
    path: 'map',
    component: HomeMapComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'shop',
    component: AddShopComponent,
    canActivate: [AuthGuard]
  }
];

// @ts-ignore
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShopRegisterRoutingModule { }
