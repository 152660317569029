import { Injectable } from '@angular/core';
import {CustomApiService} from '../../../services/custom-api.service';
import {ApiEndpoints} from '../../../constants/api-endpoints.constant';

@Injectable({
  providedIn: 'root'
})
export class ShopUtilService {

  constructor(
    private apiService: CustomApiService
  ) { }

  getPinCodeDetail(pinCode: number) {
    return this.apiService.postRequest(
      ApiEndpoints.pinCode,
      {pincode: pinCode}
    )
  }

  submitNewShop(shopRequestBody) {
    return this.apiService.postRequest(
      ApiEndpoints.newShopRequest,
      shopRequestBody
    )
  }
}
