import { Component } from '@angular/core';
import { PageRoutes } from './constants/page-routes.constant';
import {AuthService} from './services/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public pageRoutes = PageRoutes;
  constructor(
    public authService: AuthService
  ) {}
}
