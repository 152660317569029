import {Component, Input, OnInit} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import {CameraImageFieldConfigModel} from '../../../../constants/models/camera-image-field-config.model';
import {CameraUtilService} from '../../services/camera-util.service';
import {DomSanitizer} from '@angular/platform-browser';
import {CustomApiService} from '../../../../services/custom-api.service';
import {ApiEndpoints} from '../../../../constants/api-endpoints.constant';
import {firstValueFrom} from 'rxjs';
import {ApiResponseModel} from '../../../../constants/models/api-response.model';
import {
  ImageUploadUrlResponseModel
} from '../../../../constants/models/api-response-models/image-upload-url-response.model';
import {CustomToastService} from '../../../../services/custom-toast.service';

@Component({
  selector: 'app-camera-image-field',
  templateUrl: './camera-image-field.component.html',
  styleUrls: ['./camera-image-field.component.scss']
})
export class CameraImageFieldComponent implements OnInit {

  @Input() fieldConfig: CameraImageFieldConfigModel;

  images: Record<string, any[]>;

  constructor(
    private cameraService: CameraUtilService,
    private sanitization: DomSanitizer,
    private apiService: CustomApiService,
    private toastService: CustomToastService
  ) { }

  ngOnInit(): void {
    this.initComponent();
  }

  ionViewWillEnter() {
    this.initComponent();
  }

  initComponent() {
    this.cameraService.checkPermission().then((status) => {
      console.log(status);
      // @ts-ignore
      if (status.camera !== 'granted') {
        this.cameraService.requestPermission().then((result) => {
          console.log(result);
        }, (rejected) => {
          console.log('REJECTED');
          // TODO: Handle camera permission rejection
        });
      }
    });
    this.images = {};
    for (const field of this.fieldConfig.fieldsList) {
      this.images[field.fieldName] = [];
    }
  }

  validateInput(): boolean {
    let retVal = true;
    for (const field of this.fieldConfig.fieldsList) {
      const fieldImageList = this.images[field.fieldName];
      if (fieldImageList.length < field.minNumber || fieldImageList.length > field.maxNumber) {
        if (fieldImageList.length < field.minNumber ) {
          this.toastService.simpleToastMessage(
            `Please upload atleast ${field.minNumber} pic for ${field.title}`,
            2500)
        }
        if (fieldImageList.length > field.maxNumber ) {
          this.toastService.simpleToastMessage(
            `You can upload atmax ${field.minNumber} pic for ${field.title}. Please remove extra pics.`,
            2500)
        }
        retVal = false;
      }
      const hasAllUploaded = fieldImageList.every((elem) => elem.isUploaded === true);
      if (!hasAllUploaded) {
        retVal = false;
        this.toastService.simpleToastMessage(
          'You have some pics which are not uploaded yet ! Please press upload against each pic to upload.',
          2500)
      }
    }
    return retVal;
  }

  getAllUploadedImages() {
    return this.images;
  }

  async getPhoto(fileFieldName) {
    try {
      const imgUri = await this.cameraService.getPhoto();
      const imgObj = {
        rawOutput: imgUri,
        base64Url: this.getCompleteBase64Url(imgUri.base64String, imgUri.format),
        format: imgUri.format,
        isUploaded: false,
        isUploading: false
      }
      const base64Resp = await fetch(imgObj.base64Url);
      imgObj['blobUrl'] = await base64Resp.blob();
      console.log(imgObj);
      this.images[fileFieldName].push(imgObj);
    } catch (e) {
      console.log(e);
    }

  }

  getCompleteBase64Url(base64String, format){
    return `data:image/${format};base64,${base64String}`;
  }

  openImage(fieldName: string, imgIndex: number) {
    return;
  }

  removePhoto(fieldName: string, imgIndex: number) {
    this.images[fieldName].splice(imgIndex, 1);
  }

  async uploadPhoto(imgObj, imgType) {
    imgObj.isUploading = true;
    const urlObj = await this.getUploadUrl(imgObj, imgType)
    if (!urlObj) {
      imgObj.isUploading = false;
      this.toastService.simpleToastMessage('Uploading one of the images failed. Please try again.');
      imgObj.isUploading = false;
      return;
    }
    const s3Resp = await this.post2S3(urlObj.postObject, imgObj.blobUrl, urlObj.formattedFileData);
    if (!s3Resp) {
      this.toastService.simpleToastMessage('Uploading one of the images failed. Please try again.');
      imgObj.isUploading = false;
      return;
    }
    imgObj.isUploading = false;
    imgObj.isUploaded = true;
    imgObj['fileId'] = urlObj.fileId;
  }

  async getUploadUrl(imgObj, imgType) {
    try {
      const resp = await firstValueFrom(this.apiService.postRequest(
        ApiEndpoints.imageUploadUrlRequest,
        {
          fileName: uuidv4()+'.'+imgObj.format,
          folderName: imgType
        }
      )) as ApiResponseModel<ImageUploadUrlResponseModel>;
      return resp.data;
    } catch (e) {
      return null;
    }

  }

  async post2S3(s3PostObject, blob, formattedFileData) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(s3PostObject?.fields)) {
      formData.append(key, value as string);
    }
    formData.append('file', blob, formattedFileData.hex+'.'+formattedFileData.ext);
    console.log(s3PostObject.url);
    try {
      const s3Resp = await firstValueFrom(this.apiService.postRequest(
        s3PostObject.url,
        formData
      ));
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
